<template>
  <div>
    <h2 color="#101318" class="pt-6 pb-3 hb-font-header-2-medium">
      Payment Information
    </h2>
    
    <hb-card class="payment-data">
      <v-container style="padding: 13px 16px 14px 16px">
        <v-row class="ma-0 pa-0">
          <v-col cols="8" class="ma-0 pa-0">
            <div v-if="paymentData.totalDue > 0 && paymentData.totalNewPaymentDue > 0 && paymentConfig.SHOW_PARTIAL_PAYMENTS" @click="partialPaymentPermission">
              <hb-checkbox
                @change="setAmountDue"
                :input-value="paymentData.partial_payment"
                :disabled="!canDoPartialPayment || ivrPaymentSessionStarted"
                label="Choose a different amount">
              </hb-checkbox>
              <div class="ml-1">
                <span class="font-weight-medium">Note: Do not use this option if taking a cash payment and change is due.</span> If change is due, please enter the amount received in the Cash Payment Transaction Details section below. <span class="font-weight-medium">Making a partial payment does not remove the tenant from a delinquent state.</span>
              </div>
            </div>
          </v-col>
          
          <v-col cols="4" class="pa-0 ma-0">
            <v-row class="font-weight-medium ma-0 pa-0">
              <v-col cols="7" align="right" class="pa-0 ma-0">
                <span>Total Payment:</span>
              </v-col>
              <v-col cols="5" class="pa-0 ma-0" align="right">
                <span v-if="!paymentData.partial_payment || paymentData.totalDue === 0">
                  {{ paymentData.totalNewPaymentDue | formatMoney }}
                </span>
                <hb-text-field
                  v-else
                  class="amount-paying"
                  type="number"
                  style="position: relative; top: 1.5px;"
                  v-model="partialPaymentAmount"
                  v-validate=" paymentData.use_credits ? '': paymentConfig.ALLOW_ADDITIONAL_PAYMENT ? 'required|min_value:.01' : `required|min_value:.01|max_value:${paymentData.totalDue}`"
                  data-vv-name="amount"
                  data-vv-as="Total Payment"
                  prepend-inner-icon="mdi-currency-usd"
                  :error-messages="errors.collect('amount')"
                  @input="partialAmountUpdate"
                  :disabled="ivrPaymentSessionStarted"
                  autofocus
                >
                </hb-text-field>
              </v-col>
            </v-row>

            <v-row class="ma-0 pa-0" v-if="(paymentData.amountTendered - paymentData.totalNewPaymentPaying > 0) && paymentMethod.type.toLowerCase() == 'cash'">
              <v-col md="8" class="ma-0 pa-0" align="right">Change Due:</v-col>
              <v-col md="4" class="ma-0 pa-0" align="right">
                {{ ( paymentData.amountTendered - paymentData.totalNewPaymentPaying ) | formatMoney }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-divider v-show="(paymentData.partial_payment || paymentData.use_credits) && paymentData.totalDue > 0"></v-divider>
      <v-container class="py-0" v-show="(paymentData.partial_payment || paymentData.use_credits) && paymentData.totalDue > 0">
        <v-row class="px-4">
          <v-col class="py-4" style="font-weight: 500">INVOICE NUMBER</v-col>
          <v-col class="py-4 text-right flex-shrink" style="font-weight: 500">INVOICE TOTAL</v-col>
          <v-col class="py-4 text-right flex-shrink" style="font-weight: 500">AMOUNT APPLIED</v-col>
        </v-row>
      </v-container>
      <v-divider v-show="(paymentData.partial_payment || paymentData.use_credits) && paymentData.totalDue > 0"></v-divider>
      <v-container v-show="(paymentData.partial_payment || paymentData.use_credits) && paymentData.totalDue > 0">
        <v-row justify="center" align="center" v-if="invoicesLoading">
          <v-col class="text-center">
            <v-progress-circular indeterminate color="primary" ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row class="px-4" v-for="(inv, i) in invoicesArray" :key="'invoice' + i" v-else>
          <v-col class="py-1" v-if="inv.id">#{{inv.number}}</v-col>
          <v-col class="py-1" v-else>{{inv.period_start | formatDate}} - {{inv.period_end | formatDate }}</v-col>
          <v-col class="py-1 text-right flex-shrink">{{inv.balance | formatMoney}}</v-col>
          <v-col class="py-1 text-right flex-shrink">{{((inv.amount) + (inv.credits_amount_used)) | formatMoney}}</v-col>
        </v-row>
      </v-container>
    </hb-card>
    <payments-notification-modal
        v-model="notificationModal"
        notificationType="manage_partial_payments"
    >
    </payments-notification-modal>
  </div>
</template>

<script type="text/babel">
import { mapGetters, mapMutations } from 'vuex';
import PaymentsNotificationModal from '../../payments/PaymentsNotificationModal.vue';
import { EventBus } from '../../../EventBus.js';

export default {
  name: "PaymentInformation",
  props: [],
  data: () => ({
    notificationModal: false,
    partialPaymentAmount: 0
  }),
  created() {   
    EventBus.$on('updatePartialPaymentAmount', this.updatePartialPayment);
    this.setPaymentInformation({property: 'partial_payment', propertyValue: false});
  },
  destroyed() {
    EventBus.$off('updatePartialPaymentAmount', this.updatePartialPayment);
    // this.resetPayments();
  },
  components: {
    PaymentsNotificationModal
  },
  computed: {
    ...mapGetters({
      ivrPaymentSessionStarted:'charmCallStore/ivrPaymentSessionStarted',
      paymentConfig: 'paymentsStore/getPaymentSourceConfig',
      hasPermission: 'authenticationStore/rolePermission',
      leasesArray: 'paymentsStore/getLeases',
      invoicesArray: 'paymentsStore/getInvoices',
      paymentMethod: 'paymentsStore/getPaymentMethod',
      paymentData: 'paymentsStore/getPaymentInformation',
      invoicesLoading: 'paymentsStore/getInvoicesLoading',
    }),
    leases_being_paid(){
      return this.leasesArray && this.leasesArray.length && this.leasesArray.filter(l => l.pay) || [];
    },
    creditsAvailableLabel() {
      return `Apply Available Reserve/Credit Balance (${this.$options.filters.formatMoney(this.$props.paymentData.totalCredits)})`
    },
    canDoPartialPayment(){
      return this.hasPermission("manage_partial_payments");
    },
  },
  methods: {
    ...mapMutations({
      setDisablePaymentButton: 'paymentsStore/setDisablePaymentButton',
      setPaymentInformationObject: 'paymentsStore/setPaymentInformationObject',
      setPaymentInformation: 'paymentsStore/setPaymentInformation',
      resetPayments: 'paymentsStore/resetPayments',
      setInvoicesLoading: 'paymentsStore/setInvoicesLoading',

    }),
    setAmountDue(){
      this.setPaymentInformation({property: 'partial_payment', propertyValue: !this.paymentData.partial_payment});
      const dueAmount =  Math.round(this.paymentData.totalNewPaymentDue * 1e2)/1e2;
      this.setPaymentInformation({property: 'totalNewPaymentPaying', propertyValue: dueAmount});
      this.setPaymentInformation({property: 'amountTendered', propertyValue: dueAmount});
      this.partialPaymentAmount = dueAmount;
      this.$emit('updatePaymentAllocation');
    },
    partialPaymentPermission(){
      if(!this.canDoPartialPayment){
        this.notificationModal = true;
      }
    },
    partialAmountUpdate(val){
      this.setDisablePaymentButton({value: true});
      this.setInvoicesLoading({value: true});
      this.sendPaymentDataUpdate();
    },

    updatePartialPayment(){
      if(this.paymentData.partial_payment) {
        if(!this.paymentData.totalNewPaymentPaying) this.setPaymentInformation({property: 'partial_payment', propertyValue: false});
        this.partialPaymentAmount = this.paymentData.totalNewPaymentPaying;
      }
    },

    sendPaymentDataUpdate : _.debounce(async function() {
      this.setPaymentInformation({property: 'totalNewPaymentPaying', propertyValue: Number(this.partialPaymentAmount) || 0});
      this.setPaymentInformation({property: 'amountTendered', propertyValue: Number(this.partialPaymentAmount) || 0});
      this.$emit('paymentDataUpdate');
    }, 1000),

    validatePaymentAmount(){
      return this.$validator.validateAll();
    }
  }
};
</script>
<style>
.amount-paying.v-text-field input {
  padding: 0px !important;
}
.amount-paying.v-input__append-outer, .v-input__prepend-outer {
  padding: 0px !important;
  margin: 0px !important;
}
.amount-paying input[type="number"]::-webkit-outer-spin-button,
.amount-paying input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
<style scoped>
.amount-paying >>> input {
  text-align: right;
}
.navigation-panel-container {
  background: #f9fafb;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.panel-content {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.user-info,
.nav-drawer-header {
  background: white;
  flex: 0 1;
}

.navigation-panel-container .v-expansion-panel {
  margin-bottom: 10px;
  margin-top: 0;
  border: 1px solid #dfe3e8;
}

.info-panel {
  flex: 1 0;
  padding: 30px 50px;
  background-color: #f9fafb;
  border-left: 1px solid #e1e6ea;
}
.walkthrough-body {
  flex: 1;
}
.section-header {
  background: white;
}

.user-info {
  padding-left: 50px;
  padding-right: 5px;
  background-color: white;
}

.v-expansion-panel-content .form-label {
  background: #f4f6f8;
  padding: 20px;
}

.v-expansion-panel-content .form-label-active {
  background: #effafa;
  padding: 20px;
}

.v-expansion-panel-content .form-label-active h3 {
  color: #00848e;
}

.invoice_display {
  padding: 10px 0px;
  font-size: 14px;
}

.invoice-line-row {
  margin-bottom: 0;
  padding: 0px 20px;
}
.invoice-line-row .col {
  padding: 0;
}

.invoice-total-row > .col {
  padding: 10px;
}

.invoice-row .caption {
  color: #637381;
}

.invoice-row > .col {
  padding-bottom: 0;
}

.invoice-line-row.total {
  border-top: 1px solid #c4cdd5;
  margin-top: 20px;
}
.invoice-line-row.total > .col {
  padding-top: 15px;
}
.payment-data {
  background: white;
  border: 1px solid #dfe3e8;
  margin-bottom: 10px;
  border-radius: 3px;
}

.payment-data-header .v-input--checkbox,
.form-label .v-input--checkbox {
  margin-top: 0;
  padding-top: 0;
}

.payment-data-header {
  border-bottom: 1px solid #dfe3e8;
  padding: 15px 20px;
  margin-bottom: 0;
}
.auto-debit-placeholder {
  border: 2px dashed #c4cdd5;
  border-radius: 4px;
  padding: 15px;
}
</style>
