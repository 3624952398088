import moment from 'moment';
import { mapGetters } from 'vuex';

export const calendarMixin = {
  name: 'calendarMixin',
  computed: {
    ...mapGetters({
          fiscalYearMonth: 'settingsStore/fiscalYearMonth',
        }),
    daysOfMonth() {
      let days = [];
      for (var i = 1; i <= 31; i++) {
        days.push(i);
      }
      return days;
    },
  },
  methods: {
    dateDifferenceInDays(start, end) {
      return end.diff(start, 'days');
    },
    getFirstDayOfYear(format = 'YYYY-MM-DD') {
          const firstDayOfYear = moment().startOf('year').format(format);
      
          if(!this.fiscalYearMonth) return firstDayOfYear;
      
          let numberOfMonths = (moment().startOf('month').diff(moment().month(this.fiscalYearMonth - 1).startOf('month').subtract(1, 'years'), 'months')) + 1;
      
          if(numberOfMonths > 12) return moment().month(this.fiscalYearMonth - 1).startOf('month').format(format);
          
          return moment().month(this.fiscalYearMonth - 1).startOf('month').subtract(1, 'years').format(format);
        }
  }
};
