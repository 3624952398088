<template>
  <div>
    <v-row justify="center" align="center" v-if="loading">
      <v-col class="text-center ma-12">
          <v-progress-circular indeterminate color="primary" ></v-progress-circular>
      </v-col>
    </v-row>

    <contact
      v-if="isMerchandiseOption && !loading && guestMerchant"
      :contact-data="contact_prop"
      :isCalculating="isCalculating"
      :disable-payment="disable_payment"
      :checkPaymentsErrors="checkPaymentsErrors"
    ></contact>
    
    <space-information
      v-if="leases && leases.length && !loading"
      @calculateFuturePayments="calculateFuturePayments"
      @updateSelectedLeasesInvoices="updateSelectedLeasesInvoices"
      :paymentSource="paymentSource"
    ></space-information>

    <product-information
      v-else-if="paymentSource == 'SERVICES' && invoices && invoices.length && !loading && !isMerchandiseOption"
      :invoice="invoice"
    ></product-information>

    <payment-information
      class="mb-6"
      @paymentDataUpdate="paymentDataUpdate"
      @updatePaymentAllocation="updatePaymentApplication"
      v-show="((leases && leases.length) || invoice ) && !loading && (!isMerchandiseOption || activeMerchandiseTab === 'fees')"
      ref="paymentInfo"
    ></payment-information>

    <collect-payment
      class="mb-6"
      v-if="payment_information.totalNewPaymentDue > 0 && payment_information.totalDue > 0 && payment_information.totalNewPaymentPaying > 0 && !loading"
      :property_id="propertyId"
      :contact_id="contactId"
      ref="collectPaymentComponent"
      :paymentSource="paymentSource"
    ></collect-payment>
  </div>
</template>

<script type="text/babel">
import moment from "moment";
import SpaceInformation from "./SpaceInformation.vue";
import ProductInformation from "./ProductInformation.vue";
import PaymentInformation from "./PaymentInformation.vue";
import CollectPayment from '../CollectPayment.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { EventBus } from '../../../EventBus.js';
import Loader from '../../assets/CircleSpinner.vue';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
import Contact from '../Merchandise/Contact.vue';

export default {
  name: "PaymentProcess",
  mixins: [ notificationMixin ],
  props: ["contactID", "propertyID", "paymentSource", "invoice", "leaseID", "transfer_lease_id", "contact_prop", "guestMerchant",'checkPaymentsErrors','isMerchandiseSource', 'isCalculating', 'disable_payment'],
  components: {
    SpaceInformation,
    ProductInformation,
    PaymentInformation,
    CollectPayment,
    Loader,
    Contact
  },

  data: () => ({
    formErrors: '',
    loading: false,
    propertyId: null,
    paymentProperty: null,
    contactId: null,
    leaseId: null
  }),

  async created() {   
    EventBus.$on('handleSourcePropertyUpdate', this.initPaymentProcess);
    EventBus.$on('updatePaymentApplication', this.updatePaymentApplication);
    EventBus.$on('updatePaymentDetails', this.refreshPaymentDetails);
    await this.initPaymentProcess();
  },

  destroyed() {
    EventBus.$off('handleSourcePropertyUpdate', this.initPaymentProcess);
    EventBus.$off('updatePaymentApplication', this.updatePaymentApplication);
    EventBus.$off('updatePaymentDetails', this.refreshPaymentDetails);
    this.closeMessageNotification();
    this.resetPayments();
  },
  computed:{
      ...mapGetters({
          leases: 'paymentsStore/getLeases',
          contact: 'paymentsStore/getContact',
          payment_methods: 'paymentsStore/getContactPaymentMethods',
          payments: 'paymentsStore/getContactOpenPayments',
          billed_months: 'onBoardingStore/billed_months',
          on_boarding_invoices: 'onBoardingStore/invoices',
          payment_information: 'paymentsStore/getPaymentInformation',
          payment: 'paymentsStore/getPayment',
          payment_method: 'paymentsStore/getPaymentMethod',
          invoices: 'paymentsStore/getInvoices',
          getErrors: 'paymentsStore/getErrors',
          template:  'onBoardingStore/template',
          configuration_unit: "onBoardingStore/configuration_unit",
          configuration_lease: "onBoardingStore/configuration_lease",
          getLeaseIndex: 'paymentsStore/getLeaseIndex',
          getPaymentSourceConfig: 'paymentsStore/getPaymentSourceConfig',
          connections: 'paymentsStore/getPropertyConnections',
          showP2PSelector: 'paymentsStore/showP2PSelector',
          selectedProperties: 'propertiesStore/filtered',
          activeMerchandiseTab: 'navigationStore/activeMerchandiseTab'
      }),
      selectedLeases() {
        return this.leases && this.leases.length && this.leases.filter(lease => lease.pay);
      },

      selectedActiveLeases() {
        return this.leases && this.leases.length && this.leases.filter(lease => lease.pay && (lease.lease_is_active || lease.lease_is_active == null));
      },

      auto_charge() {
        return this.leases && this.leases.length && this.leases.filter(l => l.auto_charge).length ? true : false;
      },
      isMerchandiseOption() {
        return this.isMerchandiseSource ? true : false;
      }
  },

  methods: {
    ...mapActions({
      payInvoice: 'onBoardingStore/payInvoice',
      fetchContactLeasesWithInvoices: 'paymentsStore/fetchContactLeasesWithInvoices',
      fetchContactPaymentMethods: 'paymentsStore/fetchContactPaymentMethods',
      fetchContactOpenPayments: 'paymentsStore/fetchContactOpenPayments',
      fetchAdvanceLeasePayments: 'paymentsStore/fetchAdvanceLeasePayments',
      fetchContactDetails: 'paymentsStore/fetchContactDetails',
      initPaymentStore: 'paymentsStore/initPaymentStore',
      fetchBilledMonthsByAmount: 'paymentsStore/fetchBilledMonthsByAmount',
      fetchPropertyConnections: 'paymentsStore/fetchPropertyConnections',
      removeExtraAdvanceInvoices: 'paymentsStore/removeExtraAdvanceInvoices'
    }),

    ...mapMutations({
      setLeaseProperty: 'paymentsStore/setLeaseProperty',
      setPaymentSource: 'paymentsStore/setPaymentSource',
      setPaymentInformation: 'paymentsStore/setPaymentInformation',
      setPayment: 'paymentsStore/setPayment',
      setPaymentMethod: 'paymentsStore/setPaymentMethod',
      setPaymentObject: 'paymentsStore/setPaymentObject',
      setPaymentMethodObject: 'paymentsStore/setPaymentMethodObject',
      setPaymentInformationObject: 'paymentsStore/setPaymentInformationObject',
      setInvoicesObject: 'paymentsStore/setInvoicesObject',
      pushInvoices: 'paymentsStore/pushInvoices',
      setProperty: 'paymentsStore/setProperty',
      setInvoice: 'paymentsStore/setInvoice',
      resetPayments: 'paymentsStore/resetPayments',
      setCheckErrors: 'paymentsStore/setCheckErrors',
      setLeaseBilledMonths: 'paymentsStore/setLeaseBilledMonths',
      setDisablePaymentButton: 'paymentsStore/setDisablePaymentButton',
      setInvoicesLoading: 'paymentsStore/setInvoicesLoading',
      setOnboardingBilledMonths: 'onBoardingStore/setBilledMonths',
      setOnboardingLeaseProperty: 'onBoardingStore/setLeaseProperty',
      removeExtraOnboardingInvoices: 'onBoardingStore/removeExtraOnboardingInvoices',
      removeExtraConfigurationInvoices: 'onBoardingStore/removeExtraConfigurationInvoices',
      setConfigurationLeaseProperty: 'onBoardingStore/setConfigurationLeaseProperty'
    }),

    async initPaymentProcess(property_id, contact_id, lease_id,refresh = false){
      this.loading = true;
      this.$emit("initialLoading",true);
      this.propertyId = property_id || this.propertyID;
      this.contactId = this.isMerchandiseOption ? this.contactID : (contact_id || this.contactID);
      this.leaseId = lease_id || this.leaseID;

      if(this.selectedProperties.length === 1){
        this.paymentProperty = this.selectedProperties[0].id;
      }else {
        this.paymentProperty = this.propertyId;
      }

      this.setPayment({property : 'property_id', propertyValue: this.paymentProperty });
      this.setPayment({property : 'contact_id', propertyValue: this.contactId});
      if(this.showP2PSelector) this.setPayment({property : 'source_property', propertyValue: this.paymentProperty});

      await this.initPaymentStore({
        paymentSource: this.paymentSource,
        contactID: this.contactId,
        propertyID: this.paymentProperty,
        setTransformedContact: true,
        guestMerchant: this.guestMerchant
      })

      if(this.invoice){
        this.setInvoicesObject({ invoices: [this.invoice] });
      }

      if(!this.invoices.length) {
        await this.fetchContactLeasesWithInvoices({
          contactID: this.contactId,
          propertyID: this.propertyId,
          leaseID: this.leaseId,
          activeDate: moment.utc().format("YYYY-MM-DD")
        });
      }

      if(this.leases?.length && !this.invoices?.length) this.updateInvoicesForActiveLeases();

      await this.setContactPayments(this.contactId, this.propertyId);
      if(refresh) {
        this.setPaymentInformation({property: 'totalNewPaymentPaying', propertyValue: this.payment_information.totalNewPaymentPaying});
        this.setPaymentInformation({property: 'amountTendered', propertyValue: this.payment_information.totalNewPaymentPaying});
        this.setTemplateAutoChargeLeases();
        this.applyPaymentToInvoices(Number(this.payment_information.totalNewPaymentPaying));
      }else{
        this.refreshPaymentDetails();
      }
      this.setTemplateAutoChargeLeases();

      await this.fetchPropertyConnections({
        propertyID: this.paymentProperty
      });

      if(!this.connections.length){
        this.showMessageNotification({type: 'error', description: "This account is not set up to accept online payments."});
      }

      this.loading = false;
      this.$emit("initialLoading",false);
    },

    refreshPaymentDetails() {
      this.totalDue();
      this.totalCredits();
      this.totalNewPaymentDue();
      this.setAmountDue();
      this.setTemplateAutoChargeLeases();
      this.applyPaymentToInvoices(Number(this.payment_information.totalNewPaymentPaying));
    },

    updatePaymentApplication(){
      this.applyPaymentToInvoices(Number(this.payment_information.totalNewPaymentPaying));
    },

    // Updated Auto Pay checkbox on Payment Screen based on the lease configuration if Auto Pay is enabled/disabled
    setTemplateAutoChargeLeases() {
      if(this.leases && this.leases.length) {
        this.leases.map((l, i) => {
          let selected_unit = l.unit_id ? l.unit_id : this.configuration_unit.id;
          let unit_template = this.template[selected_unit];
          if(unit_template && unit_template.auto_pay) {
            let pValue = this.payment_information.totalNewPaymentDue > 0 ? true : false;
            this.setLeaseProperty({ leaseIndex: i, property: 'auto_charge', propertyValue: pValue});
          }
        });
      }
    },

    autoPayLeases() {
      let auto_pay_leases = [];
      this.leases.map(l => {
        if(l.auto_charge){
          auto_pay_leases.push({lease_id: l.id});
        }
      });
      return auto_pay_leases;
    },
    resetPaymentInfo() {
      this.setPaymentInformation({property: 'totalNewPaymentPaying', propertyValue: 0});
    },

    async applyPaymentToInvoices(amount) {
      const tempInvoices = [...this.invoices];
      let updatedInvoices = [];
      let invoicesToRemove = {};

      let creditsRemaining =
        (this.payment_information.use_credits || this.payment_information.transferCredits > 0) ?
        this.payment_information.totalCredits + this.payment_information.transferCredits
        : 0;

      let newPaymentAmountRemaining = amount;
      let totalRemainingAmount = newPaymentAmountRemaining + creditsRemaining;

      for(let j = 0; j < tempInvoices.length; j++) {
        let tempInvoice = tempInvoices[j];
        let invoice = {};

        invoice.id = tempInvoice.id ? tempInvoice.id : null;
        invoice.number = tempInvoice.number;
        invoice.balance = tempInvoice.balance;
        invoice.date = tempInvoice.date;
        invoice.period_start = tempInvoice.period_start;
        invoice.period_end = tempInvoice.period_end;
        invoice.lease_id = tempInvoice.lease_id;
        invoice.due = tempInvoice.due;

        if(totalRemainingAmount >= tempInvoice.balance) {

          let creditsRemainingBeforeApplyingToInvoice = creditsRemaining;
          if(creditsRemainingBeforeApplyingToInvoice > 0) {
            creditsRemaining = Math.round((creditsRemaining - tempInvoice.balance) * 1e2) / 1e2;
          }

          invoice.credits_amount_used = this.calculateCreditsUsedInInvoice(creditsRemaining, creditsRemainingBeforeApplyingToInvoice, tempInvoice);
          invoice.amount = tempInvoice.balance - invoice.credits_amount_used;
          totalRemainingAmount = Math.round((totalRemainingAmount - tempInvoice.balance) * 1e2) / 1e2;
        } else {

          let creditsRemainingBeforeApplyingToInvoice = creditsRemaining;
          if(creditsRemainingBeforeApplyingToInvoice > 0) {
            creditsRemaining = Math.round((creditsRemaining - tempInvoice.balance) * 1e2) / 1e2;
          }

          let unusedCredits = creditsRemaining;
            if(unusedCredits > 0) {
            creditsRemaining = Math.round((creditsRemaining - tempInvoice.balance) * 1e2) / 1e2;
          }

          invoice.credits_amount_used = this.calculateCreditsUsedInInvoice(creditsRemaining, creditsRemainingBeforeApplyingToInvoice, tempInvoice);
          invoice.amount = totalRemainingAmount - invoice.credits_amount_used;
          totalRemainingAmount = 0;
        }
        updatedInvoices.push(invoice);
      }

      for(const invoice of updatedInvoices){
        if(invoice.amount > 0 || invoice.credits_amount_used > 0 || invoice.balance === 0) continue;
        const leaseId = invoice.lease_id || 'transfer_lease';
        invoicesToRemove[leaseId] = (invoicesToRemove[leaseId] || 0) + 1;
      }

      for(const lease_id of Object.keys(invoicesToRemove)){
        const leaseIndex = lease_id === 'transfer_lease' ? 0 : (this.getLeaseIndex(lease_id) || 0);
        const lease = this.leases[leaseIndex];
        const currentBilledMonths = lease.billed_months;
        let factor = 0;
        let removedAdvanceInvoices = [];

        if(!lease.payment_cycle || lease.payment_cycle.toLowerCase() === 'monthly'){
          factor = invoicesToRemove[lease_id];
        }else{
          let divider = lease.payment_cycle.toLowerCase() === 'quarterly' ? 3 : 12;
          factor = Math.floor(invoicesToRemove[lease_id] / divider) * divider;
        }

        let updatedBilledMonths = currentBilledMonths - factor;
        this.setLeaseBilledMonths({
          leaseIndex: leaseIndex,
          billedMonths: updatedBilledMonths || 0
        });

        if(this.paymentSource === 'MOVE_IN'){
          this.setOnboardingBilledMonths(updatedBilledMonths+1);
          this.setOnboardingLeaseProperty({property: 'billed_months', propertyValue: updatedBilledMonths});
        }

        if(this.paymentSource === 'TRANSFERS'){
          this.setOnboardingBilledMonths(updatedBilledMonths+1);
          this.setConfigurationLeaseProperty({property: 'billed_months', propertyValue: updatedBilledMonths});
        }

        if(factor > 0) {
          removedAdvanceInvoices = await this.removeExtraAdvanceInvoices({leaseIndex, factor});
        }
        updatedInvoices = removedAdvanceInvoices.length ? updatedInvoices.filter(updated_inv => !removedAdvanceInvoices.some(removed_inv => updated_inv.lease_id === removed_inv.lease_id && updated_inv.period_start === removed_inv.period_start && updated_inv.period_end === removed_inv.period_end)) : updatedInvoices;
      }

      this.setInvoicesObject({ invoices: [...updatedInvoices] });

      if(this.paymentSource === 'MOVE_IN') this.removeExtraOnboardingInvoices({invoices: [...updatedInvoices]});
      if(this.paymentSource === 'TRANSFERS') this.removeExtraConfigurationInvoices({invoices: [...updatedInvoices]});

      this.totalDue();
      this.totalNewPaymentDue();

      return totalRemainingAmount;
    },

    calculateCreditsUsedInInvoice(creditsRemaining, unusedCredits, tempInvoice) {
      let credits_amount_used = 0;
      if(creditsRemaining > 0) {
        credits_amount_used = tempInvoice.balance;
      } else if(unusedCredits > 0) {
        credits_amount_used = unusedCredits;
      }
      return credits_amount_used;
    },

    setFormErrors(err){
      this.formErrors = err
    },

    totalCredits() {
      const totalCreditsAmount = this.payments && this.payments.reduce((a, p) => a + Math.round(p.amt_remaining * 1e2) / 1e2, 0);
      this.setPaymentInformation({property: 'totalCredits', propertyValue: totalCreditsAmount});
      this.setPaymentInformation({property: 'use_credits', propertyValue: !!totalCreditsAmount});
      return totalCreditsAmount || 0;
    },

    totalDue() {
      let totalDueAmount = this.invoices && this.invoices
        .map(invoice => invoice.balance)
        .reduce((prev, curr) => prev + curr, 0);

      // In case total amount is NaN
      totalDueAmount = totalDueAmount || 0;
      // In case of transfer, use transfer out balance
      const paymentDue =
        Math.max(0, totalDueAmount - this.payment_information.transferCredits).toFixed(2);

      this.setPaymentInformation({property: 'totalDue', propertyValue: Number(paymentDue) || 0})

      return paymentDue;
    },

    totalNewPaymentDue() {
      const totalNewPaymentDueAmount =  this.payment_information.use_credits ? Math.max(0, this.payment_information.totalDue - this.payment_information.totalCredits) : Number(this.payment_information.totalDue) || 0;
      this.setPaymentInformation({property: 'totalNewPaymentDue', propertyValue: totalNewPaymentDueAmount});
      return totalNewPaymentDueAmount;
    },

    setAmountDue(updateAmountTendered){
      const paymentAmount = Math.round(this.payment_information.totalNewPaymentDue * 1e2)/1e2;
      this.setPaymentInformation({property: 'totalNewPaymentPaying', propertyValue: paymentAmount});
      if(updateAmountTendered !== false) {
        this.setPaymentInformation({property: 'amountTendered', propertyValue: paymentAmount});
      }
    },

    removeInvoices(lease_id, removeOpenInvoices, updateAmounts = false, updatePaymentAmount = true) {
      let previousInvoicesExcludingModified = [];

      if(removeOpenInvoices) {
        previousInvoicesExcludingModified = this.invoices.filter(invoice => invoice.lease_id != lease_id);
      } else {
        previousInvoicesExcludingModified = this.invoices.filter(invoice => invoice.lease_id != lease_id || invoice.id != null);
      }

      this.setInvoicesObject({ invoices: previousInvoicesExcludingModified });
      if(updateAmounts){
        this.totalDue();
        this.totalCredits();
        this.totalNewPaymentDue();
        if(updatePaymentAmount) this.setAmountDue();
        EventBus.$emit('updatePartialPaymentAmount');
        this.applyPaymentToInvoices(Number(this.payment_information.totalNewPaymentPaying));
        this.enableButtons();
        this.setDisablePaymentButton({value: false});
        this.setInvoicesLoading({value: false});
      }
    },

    addInvoices(invoices, newOpenInvoices = []) {
      if (invoices) {
        let { lease_id } = invoices[0];
        // Don't exclude already created invoices which have invoice_id
        let previousInvoicesExcludingModified = this.invoices.filter(
          (invoice) => invoice.lease_id != lease_id // || invoice.id != null
        );
        this.setInvoicesObject({ invoices: [...previousInvoicesExcludingModified, ...invoices, ...newOpenInvoices] });
      }
      this.sortInvoicesByDate();
    },

    // Flow on adding additional month is as follows :
    /*
      1. Call api to get future invoices of some lease (selected from drop down)
      2. Remove the entries from previous invoices array for this lease which were present previously
      3. Add the new invoices for this lease (result come from api)
      4. Sort the invoices with start date (To pay older invoice first)
      5. Calculate amounts / credits utilization
    */
    async calculateFuturePayments(lease, leaseIndex, setPayingAmount = true) {
      this.disableButtons();
      this.setDisablePaymentButton({value: true});
      this.setInvoicesLoading({value: true});
      this.setLeaseInvoiceLoading({ value: true, leaseIndex });
      
      if (!lease.billed_months && this.paymentSource != 'MOVE_IN' && this.paymentSource != 'TRANSFERS') {
        this.setLeaseProperty({
          leaseIndex,
          property: "AdvanceInvoices",
          propertyValue: []
        });

        if(lease?.use_old_invoices){
          this.setLeaseProperty({
            leaseIndex,
            property: 'use_old_invoices',
            propertyValue: false
          });
          if(lease.pay)
            this.addInvoices(lease.oldOpenInvoices);
        }

        this.removeInvoices(lease.id, null ,true, setPayingAmount);
        return;
      }

      await this.fetchAdvanceLeasePayments({ lease, leaseIndex, billed_months: lease.billed_months, payment_cycle: lease.payment_cycle, transfer_lease_id: this.transfer_lease_id});

      if(lease.pay) {
        this.addInvoices(this.leases[leaseIndex].AdvanceInvoices, this.leases[leaseIndex].OpenInvoices);
      }
      
      this.totalDue();
      this.totalCredits();
      this.totalNewPaymentDue();
      if(setPayingAmount) this.setAmountDue();
      EventBus.$emit('updatePartialPaymentAmount');
      this.applyPaymentToInvoices(Number(this.payment_information.totalNewPaymentPaying));
      this.enableButtons();
      this.setDisablePaymentButton({value: false}); 
      this.setInvoicesLoading({value: false});
      this.setLeaseInvoiceLoading({ value: false, leaseIndex });
    },

    sortInvoicesByDate() {
      const tempInvoices = [...this.invoices];
      tempInvoices.sort((a, b) => new Date(a.due) - new Date(b.due));
      this.setInvoicesObject({ invoices: tempInvoices });
    },

    async setContactPayments(contactId, propertyId) {
      await this.fetchContactOpenPayments({
        contactID: contactId,
        propertyID: propertyId
      });
    },

    resetPaymentMethod() {
      this.setPayment({
        property: "type",
        propertyValue: ""
      });

      this.setPaymentMethod({
        property: "type",
        propertyValue: ""
      });
    },
    
    async paymentDataUpdate(){
      if(this.payment.id && this.payment.amt_remaining < this.payment_information.totalNewPaymentPaying){
        this.showMessageNotification({type: 'error', description: 'There is only ' + this.payment.amt_remaining + ' remaining on this ' + this.payment.credit_type});
        this.enableButtons();
        return;
      }

      if(Number(this.payment_information.totalNewPaymentPaying) > Number(this.payment_information.totalNewPaymentDue) && !this.selectedActiveLeases.length){
        this.showMessageNotification({type: 'error', description: 'There is only $'  + this.payment_information.totalNewPaymentDue + ' balance left for closed lease(s).'});
        this.setPaymentInformation({property: 'totalNewPaymentPaying', propertyValue: Number(this.payment_information.totalNewPaymentDue)});
        this.setPaymentInformation({property: 'amountTendered', propertyValue: Number(this.payment_information.totalNewPaymentDue)});
        this.updatePaymentApplication();
        EventBus.$emit('updatePartialPaymentAmount');
        this.enableButtons();
        return;
      }

      if(Number(this.payment_information.totalNewPaymentPaying) > Number(this.payment_information.totalNewPaymentDue) && this.getPaymentSourceConfig.ALLOW_ADDITIONAL_PAYMENT) {
        const res = await this.fetchBilledMonthsByAmount();
        const leases = res.leases;

        for(let i = 0; i < leases.length; i++) {
          const lease = { id: leases[i]?.id || null, billed_months: leases[i].billed_months, pay: true, payment_cycle: leases[i]?.payment_cycle || null, auto_charge: leases[i]?.auto_charge || false };
          const leaseIndex = this.getLeaseIndex(lease.id) || 0;
        
          this.setLeaseBilledMonths({
            leaseIndex: leaseIndex,
            billedMonths: leases[i].billed_months || 0
          });
          await this.calculateFuturePayments(lease, leaseIndex, false);
        }
      }else{
        this.applyPaymentToInvoices(Number(this.payment_information.totalNewPaymentPaying) || 0);
      }
      this.enableButtons();
      this.setDisablePaymentButton({value: false}); 
      this.setInvoicesLoading({value: false});
    },

    updateInvoicesForActiveLeases(){
      this.setInvoicesObject({invoices: []});
      this.selectedLeases.forEach(lease => {
        this.pushInvoices({ invoices: lease.AdvanceInvoices });
        this.pushInvoices({ invoices: lease.OpenInvoices });
      });
    },

    updateSelectedLeasesInvoices(){
      this.updateInvoicesForActiveLeases();
      this.sortInvoicesByDate();
      this.totalDue();
      this.totalCredits();
      this.totalNewPaymentDue();
      this.setAmountDue();
      EventBus.$emit('updatePartialPaymentAmount');
      this.applyPaymentToInvoices(Number(this.payment_information.totalNewPaymentPaying));
    },

    async checkFieldValidations(){
      let validated  =  true;
      if(this.payment_information.partial_payment) validated = validated && this.$refs.paymentInfo.validatePaymentAmount();
      if(this.payment_information.totalNewPaymentDue > 0 && this.payment_information.totalNewPaymentPaying > 0) validated = validated && await this.$refs.collectPaymentComponent.validateDataFields();
      return validated;
    },

    enableButtons(){
      this.setDisablePaymentButton({value: false}); 
      this.setInvoicesLoading({value: false});
    },

    disableButtons(){
      this.setDisablePaymentButton({value: true}); 
      this.setInvoicesLoading({value: true});
    },

    setLeaseInvoiceLoading(payload){
      let { value, leaseIndex } = payload;
      this.setLeaseProperty({
        leaseIndex,
        property: "leaseInvoiceLoading",
        propertyValue: value
      });
    },
  },

  watch: {
    async getErrors(value) {
      if(value === true){
        const status = await this.checkFieldValidations();
        status ? this.setCheckErrors({ value: false }) : this.setCheckErrors({ value: null });
      }
    },
    getPaymentSourceConfig(val) {
      if(val.SHOW_SAVE_PAYMENT === true) {
        this.setPaymentMethod({property: 'save_to_account', propertyValue: true})
      }
    },

    invoice(val) {
      if(this.invoice){
        this.setInvoicesObject({ invoices: [this.invoice] });
        this.refreshPaymentDetails();
      }
    },
    async contactID(params) {
      if (this.isMerchandiseOption && params) {
        this.$nextTick(() => {
          this.initPaymentProcess()
          .then(async (res) => {
            await EventBus.$emit('HB-Merchandise-Sell:handle-contact-change');
          });
        });
      }
    }
  }
};
</script>

<style scoped></style>
