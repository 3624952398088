<template>
  <div v-resize="adjustAddPhoneBottomAction">
    <v-alert
      dense
      outlined
      :value="error"
      dismissible
      type="error">
      Please review fields
    </v-alert>
    <v-card flat>
        <v-card-title
          class="title"
          primary-title
          v-if="interactiveMode === undefined || !interactiveMode"
        >
        <span>Add Phone Call</span>
        <v-row justify="end">
          <v-btn icon @click="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
        <v-card-text class="pl-3 py-0 pr-0 hide-overflow d-flex flex-column">
          <v-row ref="richTextEditor"
           class="pr-3 rich-text-editor">
            <v-col class="pa-0" cols="12">
              <v-textarea
                solo
                dense
                hide-details
                flat
                background-color="#FFFFFF"
                :style="`height:${richTextEditorHeight > 0 && usageContext ? richTextEditorHeight: textareaStyle}px`"
                auto-grow
                v-model="content"
                placeholder="Compose a note about this call..."
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters class="pt-3">
            <v-col>
                <div class="hb-text-night hb-font-body-medium">
                  Call Direction
                </div>
            </v-col>
            <v-col>
              <hb-radio-group row v-model="logDirection">
                <hb-radio label="Incoming" value="incoming"></hb-radio>
                <hb-radio label="Outgoing" value="outgoing"></hb-radio>
              </hb-radio-group>
            </v-col>
          </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions ref="addPhoneCallActions" class="py-3">
<!--        <v-btn icon> <v-icon>mdi-paperclip</v-icon> </v-btn>-->
<!--        <v-btn icon> <v-icon>mdi-plus</v-icon>  </v-btn>-->
        <v-spacer></v-spacer>
          <hb-link class="mr-2" @click="onClose">Cancel</hb-link>
          <hb-btn class="mr-2" color="primary" @click="SendPhoneCall">Log</hb-btn>
      </v-card-actions>
    </v-card>
  </div>

</template>

<script>
import api from '../../assets/api.js';
import { EventBus } from '../../EventBus.js';
import { stickBottomActionBarToBottom } from '../../utils/common.js';
export default {
  name:'AddPhone',
  data () {
    return {
      richTextEditorHeight:0,
      content: '',
      error:false,
      textareaStyle: '',
      logDirection: 'incoming'
    }
  },
  props: ['contactId','onClose','interactiveMode','textarea_height', 'context','onChatError','property_id','usageContext'],
  created () {
    this.textareaStyle = this.$props.textarea_height + 98
    console.log('property_id',this.property_id);
  },
  mounted(){
    this.$nextTick(()=>{
     this.adjustAddPhoneBottomAction()
    })
    EventBus.$on('onInteraction', this.adjustAddPhoneBottomAction) 
  },
  beforeDestroy(){
      this.onChatError('')
      EventBus.$off('onInteraction')
  },
  methods: {
    adjustAddPhoneBottomAction(){
      const addPhoneCallActions = this.$refs.addPhoneCallActions
      const richTextEditor = this.$refs.richTextEditor
      this.richTextEditorHeight = stickBottomActionBarToBottom(richTextEditor,addPhoneCallActions)
    },
    async SendPhoneCall () {

      if (this.content.trim() !== '') {
        try {

            var data = {
                content: this.content,
                method: 'phone_call',
                created: new Date(),
                context: this.context,
                pinned: false,
                property_id: this.property_id,
                direction: this.logDirection
            }
            await api.post(this, api.CONTACTS + this.contactId + '/interaction', data)
            this.onClose(true);
            EventBus.$emit('interaction_saved');
        } catch(err) {
            this.onChatError('An error occurred. Could not save phone call info');
        }
      } else {
        this.error = true
      }
    }
  }
}
</script>


<style scoped>
.rich-text-editor {
  overflow-y: auto;
}
.label-div {
  background-color: #F9FAFB;
  height: 48px;
  color: #101318;
}
.hide-overflow {
  overflow: hidden;
}
</style>
