<template>
  <div class="contact-details" :class="{ 'pr-3' : $vuetify.breakpoint.width > 480}">
    <!-- <div class="main-header">
      <v-row class="mx-0">
        <v-col cols="12">
          <div class=" ml-2">
            <v-icon class="mr-4" @click="$emit('close')">mdi-arrow-left</v-icon>
            <span class="main-header-label">Payment Methods</span>
             <a class="mr-2 float-right main-header-link"  color="" @click="AddPaymentMethod" @refreshData="fetchPaymentMethods" >+ Add New Payment Method</a>

          </div>
        </v-col>
      </v-row>
    </div> -->
      <div class="py-2 pl-5 d-flex align-center" v-if="!fromTenantProfile">
        <hb-btn class="mr-2" icon tooltip="Back" @click="$emit('close')" active-state-off>mdi-chevron-left</hb-btn>
        <span class="hb-larger-font-size font-weight-medium">Payment Methods</span>
        <v-spacer></v-spacer>
        <hb-btn color="secondary" small class="mr-4" @click="AddPaymentMethod">Add New Payment Method</hb-btn>
      </div>
      <div class="pt-4 pb-0 d-flex align-center" v-else>
        <v-spacer></v-spacer>
        <hb-btn color="secondary" small class="mr-6" @click="AddPaymentMethod">Add New Payment Method</hb-btn>
      </div>
      <v-divider v-if="!fromTenantProfile"></v-divider>

    <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
    <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success" style="position:absolute;bottom:.5%;right:1.6%;width:40%"></status>

    <div class="payment-panel" :class="{'pl-1 pt-3' : fromTenantProfile, 'pr-0' : fromTenantProfile && !showAside}">
      
      <div v-if="!paymentMethods.length" class="pa-5 text-center">
        <p>No Payment Methods found. Click below to add one.</p>
        <br />
        <hb-btn @click="AddPaymentMethod">+ Add New Payment Method</hb-btn>
      </div>
    
      <div class="panel-item mb-4" :class="{ 'hb-elevation-medium' : panel === index }" v-for="(pm, index) in paymentMethods" :key="'panel_'+index">

        <div class="panel-header">
          <v-row class="mx-0">
            <v-col cols="12">

                <img v-if="pm.type === 'card'" class="cc-icon" :src="pm | getCCIcon" />
                <img v-else-if="pm.type === 'ach'" class="cc-icon" :src="'/img/cc_icons/64/ach.png'" />
                <img v-else-if="pm.type === 'directdebit'" class="cc-icon" :src="'/img/cc_icons/64/directdebit.png'" />

                <span class="hb-font-body text-capitalize pt-2"> {{pm.card_type}}</span>
                <span class="hb-font-body mx-1">ending in</span>
                <span v-if="pm.type === 'card'" class="hb-font-body-medium">{{pm.card_end}}</span>
                <span v-if="pm.type === 'ach'" class="hb-font-body-medium">{{pm.card_end.slice(-4)}}</span>
                <span v-if="pm.type === 'directdebit'" class="hb-font-body-medium">{{pm.card_end.slice(-4)}}</span>

                <span class="float-right d-flex align-center">
                  <span v-if="pm.type === 'card'" class="mr-3 header-label-2">Exp: {{pm.exp_warning | formatDateCustom('MM/YY')}}</span>
                  <a class="text-capitalize mr-2 " color="secondary"  v-show="!pm.editable" @click="makeEditable(index)">Edit</a>
                  <v-icon class="mr-2" v-show="panel === index" @click="unExpandPanel(index)">mdi-chevron-up</v-icon>
                  <v-icon class="mr-2" v-show="panel !== index" @click="expandPanel(index)">mdi-chevron-down</v-icon>
                </span>

            </v-col>
          </v-row>
        </div>

        <div class="panel-body" v-if="panel === index">
          <v-row class="mx-0">
            <v-col cols="12" class="pa-0">
                <v-card flat>
                  <v-divider></v-divider>
                  <v-card-text class="pa-0">
                    <v-row class="mx-0">
                      <v-col class="payment-label pa-0" cols="4">
                        <div class="payment-label">
                          <div class="pa-4">
                            <span class="hb-font-body-medium">Name on card</span>
                          </div>
                        </div>
                      </v-col>
                      <v-col class="py-4 px-6" cols="8">
                        <span class="hb-font-body">{{pm.name_on_card}}</span>
                      </v-col>
                      <v-col cols="12" class="pa-0">
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>

                    <v-row class="mx-0" v-if="!pm.editable && pm.type != 'ach' && !isFatzebra">
                      <v-col class="payment-label pa-0" cols="4">
                        <div class="payment-label">
                          <div class="pa-4">
                            <span v-if="isTenantPayments" class="hb-font-body-medium">Billing Zip</span>
                            <span v-else-if="!isFatzebra" class="hb-font-body-medium">Billing Address</span>
                          </div>
                        </div>
                      </v-col>

                      <div v-if="!isTenantPayments && !isFatzebra">
                        <v-col class= "pb-0 pt-4 px-6" cols="8">
                        <span class="hb-font-body">{{pm.Address.address}}</span><br>
                        <div v-if="pm.Address.address2">
                          <span class="hb-font-body" >{{pm.Address.address2}}</span><br>
                        </div>

                        <span class="hb-font-body">{{ pm.Address | formatAddress }}</span><br>
                      </v-col>
                    </div>
                    <div v-else>
                      <v-col class="pb-0 pt-4 px-6">
                      <span class="hb-font-body">{{ pm.Address.zip }}</span><br>
                      </v-col>
                    </div>
                      <v-col cols="12" class="pa-0">
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                    <v-row class="mx-0" v-if="pm.editable && pm.type != 'ach' && !isFatzebra">
                      <v-col class="payment-label pa-0" cols="4">
                        <div class="payment-label">
                          <div class="pa-4">
                            <span v-if="isTenantPayments" class="hb-font-body-medium">Billing Zip</span>
                            <span v-else-if="!isFatzebra" class="hb-font-body-medium">Billing Address</span>
                          </div>
                        </div>
                      </v-col>
                      <v-col class="pb-4 pt-0 px-6 mt-3" cols="8">
                        <HbAutocomplete
                        v-if="!isTenantPayments && !isFatzebra"
                          :items="countriesListMixin"
                          hide-details
                          v-validate="'required'"
                          v-model="payment_method.country"
                          label="Country*"
                          single-line
                          id="country"
                          data-vv-as="Country"
                          data-vv-scope="payment_method"
                          name="country"
                          :error-messages="errors.collect('payment_method.country')"
                          @change="setCountry"
                        >
                        </HbAutocomplete>
                        <v-text-field
                        v-if="!isTenantPayments && !isFatzebra"
                          hide-details
                          v-model="payment_method.address"
                          v-validate="'required|max:100'"
                          data-vv-name="address"
                          data-vv-scope="payment_method"
                          class="hb-font-body mb-3"
                          :error="errors.has('payment_method.address')"
                          label="Address 1*"
                          >
                        </v-text-field>
                        <v-text-field
                        v-if="!isTenantPayments && !isFatzebra"
                          hide-details
                          v-model="payment_method.address2"
                          v-validate="'max:100'"
                          data-vv-name="address2"
                          data-vv-scope="payment_method"
                          class="hb-font-body mb-3"
                          :error="errors.has('payment_method.address2')"
                          label="Address 2"
                        >
                        </v-text-field>
                        <v-row>
                          <v-col cols="4" class="py-0 pr-0">
                            <v-text-field
                            v-if="!isFatzebra"
                              hide-details
                              v-model="payment_method.zip"
                              v-validate="'required|max:10'"
                              data-vv-name="zip"
                              data-vv-scope="payment_method"
                              class="hb-font-body"
                              :error="errors.has('payment_method.zip')"
                              label="Zip*"
                              >
                            </v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <!-- <vuetify-statepicker
                              :name="'state'"
                              v-model="payment_method.state"
                              v-if="!isTenantPayments"
                              v-validate="'required'"
                              label="State*"
                              data-vv-as="State"
                              data-vv-scope="payment_method"
                              :error_message="errors.collect('payment_method.state')"
                            /> -->
                            <HbAutocomplete
                            v-if="!isTenantPayments && !isFatzebra"
                              :items="paymentStateListMixin"
                              class='padding-top-9'
                              hide-details
                              item-text='name'
                              item-value='value'
                              v-model="payment_method.state"
                              label="State"
                              single-line
                              id="state"
                              data-vv-as="State"
                              data-vv-scope="payment_method"
                              name="state"
                              :error-messages="errors.collect('payment_method.state')"
                              @change="setState"
                            />
                          </v-col>
                        </v-row>
                        <!-- <v-text-field
                          hide-details
                          v-model="payment_method.city"
                          v-validate="'required|max:45'"
                          data-vv-name="city"
                          data-vv-scope="payment_method"
                          class="hb-font-body"
                          :error="errors.has('payment_method.city')"
                          label="City*"
                          >
                        </v-text-field> -->
                        <HbAutocomplete
                        v-if="!isTenantPayments && !isFatzebra"
                          hide-details
                          class="mt-3"
                          v-validate="paymentCityListMixin.length ? 'required' : ''"
                          :items="paymentCityListMixin"
                          v-model="payment_method.city"
                          label="City*"
                          single-line
                          id="city"
                          data-vv-as="City"
                          data-vv-scope="payment_method"
                          name="city"
                          :error-messages="errors.collect('payment_method.city')"
                          @keydown.enter="setCityList($event.target.value, true)"
                        >
                        </HbAutocomplete>
                      </v-col>
                      <v-col cols="12" class="pa-0">
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>

                    <hb-bottom-action-bar
                      v-if="pm.editable"
                      @close="pm.editable=false"
                      :top-border="false"
                      border-bottom-radius
                    >
                      <template v-slot:right-actions>
                        <hb-btn
                          color="destructive"
                          @click="removeWarning(pm.id)"
                        >
                          Remove
                        </hb-btn>
                        <hb-btn
                          v-if="pm.type != 'ach'"
                          color="primary"
                          @click="save(pm.id)"
                          :disabled="isLoading($options.name)"
                          :loading="isLoading($options.name)"
                        >
                          Save
                        </hb-btn>
                      </template>
                    </hb-bottom-action-bar>

                  </v-card-text>
                </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>

    <add-payment-method
      v-if="addPaymentMethodFlag"
      v-model="addPaymentMethodFlag"
      :property_id="selected_property_id"
      :contact="contact"
      @close="addPaymentMethodFlag = false"
      @refreshData="fetchPaymentMethods">
    </add-payment-method>

    <v-dialog
      v-model="selectPropertyFlag"
      width="360"
      persistent
    >
      <v-card>
        <v-card-title primary-title>
          Select Property
          <v-spacer></v-spacer>
          <v-icon @click="selectPropertyFlag=false">mdi-close</v-icon>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-divider></v-divider>
          <v-row class="mx-0">
            <v-col cols="12" class="px-6">
              <v-select
              :items="properties"
              v-model="selected_property_id"
              item-text="name"
              item-value="id"
              label="Select property"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <a class="text-capitalize mr-2" color="secondary" @click="selectPropertyFlag=false">Cancel</a>

          <v-btn
            class="text-capitalize"
            color="primary"
            @click="nextOrAdd"
          >
            Next
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <hb-modal
      size="medium"
      v-model="removePaymentMethodFlag"
      show-help-link
      title="Remove Payment Method"
      confirmation
    >
      <template v-slot:content
          >
          <div class="px-6 py-4">
            Are you sure you want to remove this payment method?
          </div>
      </template>
      <template v-slot:actions>
          <hb-btn color="destructive" @click="remove">Remove</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script>

import api from '../../assets/api.js'
import Status from '../includes/Messages.vue';
import { mapActions } from 'vuex';
import VuetifyStatepicker from '../includes/VuetifyStatepicker';
import AddPaymentMethod from './AddPaymentMethod.vue'
import { EventBus } from '../../EventBus.js';
import { notificationMixin } from  '../../mixins/notificationMixin.js';
import { countryStateCityMixin } from '../../mixins/countryStateCityMixin';

export default {
  name: 'ContactPaymentMethods',
  mixins: [ notificationMixin, countryStateCityMixin ],
  data() {
    return {
      paymentMethods: [],
      panel: null,
      payment_method: {},
      addPaymentMethodFlag: false,
      selectPropertyFlag: false,
      removePaymentMethodFlag: false,
      properties: [],
      selected_property_id: '',
      payment_method_id: '',
      contact_id: '',
      pageCreated: false,
      connections: [],
      isTenantPayments: false,
      isFatzebra: false
    }
  },
  props: ['contact','close','propertyView','fromTenantProfile','showAside', 'payment_methods'],
  components: {
    VuetifyStatepicker,
    AddPaymentMethod,
    Status
  },
  filters:{
    getCCIcon(method){
        var icon = ( ['amex', 'visa', 'mastercard', 'jcb'].indexOf(method.card_type) >= 0 ) ? method.card_type: 'credit_card';
        return  '/img/cc_icons/64/'+ icon +'.png';
    }
  },
  async created () {
    this.paymentMethods = this.payment_methods;
    this.setCountriesListEvent()
    if(this.contact.id) {
      this.contact_id = this.contact.id
    } else if(this.$route.params.contact_id){
      this.contact_id = this.$route.params.contact_id;
    }
    //this.fetchPaymentMethods();
    this.pageCreated = true;
    await this.getConnection();
  },
  destroyed(){
    this.resetMissingCityList()
  },
  methods: {
    setCountry(){
      this.payment_method.state = ''
      this.payment_method.city = ''
      this.setPaymentStatesListEvent(this.payment_method.country)
      this.setPaymentCountryCitiesListEvent(this.payment_method.country)
    },
    setState(){
      this.payment_method.city = ''
        this.setPaymentCitiesListEvent(this.payment_method.country, this.payment_method.state)
    },
    setCityList(value, toValidate = false){
      if(!value) return
      const isSpecialCharacter = this.validateCityName(value)
      const cityValidation = this.errors.items.indexOf('payment_method.city')
      if(toValidate){
          this.errors.items.splice(cityValidation);
          if(isSpecialCharacter){
              return this.errors.add({
                          field: 'payment_method.city',
                          msg: 'Please add a valid city name.'
                      }) 
        }
      }
      const isCityExists = this.paymentCityListMixin.some((city) => city.toLowerCase() === value.toLowerCase())
      if(isCityExists) return

      this.paymentCityListMixin.push(value)
      const country = this.countriesListMixinRaw.find(item => item.name === this.payment_method.country);
      this.addMissingCity({
            country_code: country.isoCode,
            state_code: this.payment_method.state,
            city: value
      })
    },
    fetchPaymentMethods(){
      EventBus.$emit('payment_methods_updated');
      // this.paymentMethods = this.payment_methods.map(pm => {
      //   this.$set(pm,'editable', false);
      //   return pm; 
      // });

      // api.get(this, api.CONTACTS + this.contact_id + '/payment-methods').then(r => {
      //   this.paymentMethods = []
      //   let payment_method = {}
      //   let property_list = []
      //   let property = null
        
      //   if(this.fromTenantProfile){
      //     var allPaymentMethods = r.payment_methods.map(pm => {
      //       return {
      //         ...pm,
      //         editable: false
      //       }
      //     })

      //     this.paymentMethods = allPaymentMethods.filter(paymentMethod => 
      //       paymentMethod.property_id === this.propertyView
      //     );

      //   } else {

      //     this.paymentMethods = r.payment_methods.map(pm => {
      //       return {
      //         ...pm,
      //         editable: false
      //       }
      //     })

      //   }

      // });
      
      //EventBus.$emit('autopay_load');
    },
    expandPanel (index) {
      this.isTenantPayments = false;
      this.panel = index
      for(let i=0;i<this.paymentMethods.length;i++) {
        if(i === index) {
        if(this.paymentMethods[i].type==='card' && this.connections && this.connections.find(c => c.name === 'tenant_payments' && c.type === 'card')){
            this.isTenantPayments = true;
          }
        if(this.paymentMethods[i].type==='card' && this.connections && this.connections.find(c => c.name === 'fatzebra' && c.type === 'card')){
            this.isFatzebra = true;
        }
        if(this.paymentMethods[i].type==='directdebit' && this.connections && this.connections.find(c => c.name === 'fatzebra' && (c.type === 'card' || c.type === 'directdebit' ))){
            this.isFatzebra = true;
        }
          
        }
      }
    },
    unExpandPanel (index) {
      this.panel = null
    },
    async makeEditable (index) {
      this.isTenantPayments = false;
      this.isFatzebra = false;
      for(let i=0;i<this.paymentMethods.length;i++) {
        if(i === index) {
          this.paymentMethods[i].editable = true
          this.payment_method = JSON.parse(JSON.stringify(this.paymentMethods[i].Address))
          if(this.paymentMethods[i].type==='card' && this.connections && this.connections.find(c => c.name === 'tenant_payments' && c.type === 'card')){
            this.isTenantPayments = true;
          }
          if(this.paymentMethods[i].type==='card' && this.connections && this.connections.find(c => c.name === 'Fatzebra' && c.type === 'card')){
            this.isFatzebra = true;
          }
          this.expandPanel(index)
        } else{
          this.paymentMethods[i].editable = false
        }
      }

      this.payment_method.country = this.setCountryNameEvent(this.payment_method.country)

      if(this.payment_method.country){
            this.setPaymentStatesListEvent(this.payment_method.country)
            if(!this.payment_method.state){
              this.setPaymentCountryCitiesListEvent(this.payment_method.country)
            }else{
              this.setPaymentCitiesListEvent(this.payment_method.country, this.payment_method.state)
            }
            this.setCityList(this.payment_method.city)
      }
    },
    async save(payment_method_id) {
      let status = await this.$validator.validateAll('payment_method');

      if(!status) return;

      api.put(this, api.PAYMENTS + 'payment-method/'+ payment_method_id +'/address' , this.payment_method).then(x=>{
        this.showMessageNotification({ type: 'success', description: 'Payment method has been successfully updated.' })
        this.fetchPaymentMethods();
      })
    },
    async AddPaymentMethod () {
      const self = this
      if(!self.contact_id) {
        self.errorSet(this.$options.name, "contact_id is missing.");
      }
      api.get(self, api.CONTACTS + self.contact_id + '/leases').then(result => {
        self.properties = []
        for (let i=0;i< result.leases.length; i++) {
          let p = result.leases[i].Property
          if (self.properties.findIndex(x=> x.id === p.id) === -1) {
            self.properties.push({
              id: p.id,
              name: p.name
            })
          }
        }

        if(this.propertyView){
          self.addPaymentMethodFlag = true
          self.selected_property_id = this.propertyView
        } else if(self.properties.length === 1) {
          self.addPaymentMethodFlag = true
          self.selected_property_id = self.properties[0].id
        } else if (self.properties.length === 0) {  // when their is no lease ?
          self.errorSet(this.$options.name, "No lease found.");
          self.selectPropertyFlag = false
        } else {
          self.selectPropertyFlag = true
        }
      })

    },
    nextOrAdd() {
      this.selectPropertyFlag = false
      this.addPaymentMethodFlag = true
    },
    removeWarning (id) {
      this.removePaymentMethodFlag = true
      this.payment_method_id = id
    },
    remove() {
      api.delete(this, api.PAYMENTS + 'payment-method/'+ this.payment_method_id).then(x=> {
        this.fetchPaymentMethods();
        EventBus.$emit('payment_method_deleted', this.payment_method_id);
        this.showMessageNotification({ type: 'success', description: 'Payment method has been successfully removed.' })
        this.removePaymentMethodFlag = false;
      }) 
    },
    async getConnection(){
      let r = await api.get(this, api.PROPERTIES + this.propertyView +  '/connections');
      this.connections = r.connections;
    }
  },
  watch: {
   async propertyView() {
      if(this.pageCreated){
        this.unExpandPanel();
        await this.getConnection();
        this.fetchPaymentMethods();
      }
    },
    payment_methods(){
      this.paymentMethods = this.payment_methods;
    }
  }
}
</script>

<style scoped>
  .info-label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  span {
   font-style: normal;
   color:#101318;
  }
  .payment-panel {
    /* padding: 24px 24px 60px 24px; */
    background: #F9FAFB;
  }

  .cc-icon {
    border: 1px solid #e2e2e2;
    border-radius: 2px;
    width: 49px;
    height: 28px;
  }
  .payment-label {
    background-color: #F9FAFB;
    color: #101318;
  }

  .remove-btn {
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
    border: 1px solid #C4CDD5;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
    border-radius: 4px;
  }

    .panel-item {
      margin-bottom: 8px;
      border: 1px solid #DFE3E8;
      border-radius: 4px;
    }
    .panel-header {
        height: 52px;
        background: #FFFFFF;
        border-radius: 4px;
    }

    .header-label-1 {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;
      color: #101318;
    }
    .header-label-2 {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 26px;
      color: #101318;
    }
  .main-header{
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px rgba(11, 18, 29, 0.1);
  }
  .main-header-label{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #101318;
  }
  .main-header-link {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    color: #00848E;
  }
  .padding-top-9{
        padding-top: 9px !important;
    }
</style>
