<template>
    <div ref="responsive-tabs-wrapper" class="hb-tabs-responsive-wrapper">
        <!-- Building Menu section based on navigation chevrons visibility -->
        <template v-if="showMenu">
            <hb-menu class="hb-tabs-responsive-menu">
                <template v-slot:menu-activator>
                    <v-badge 
                        v-if="isBadgeCountPresent" 
                        color="#F26500" 
                        overlap 
                        dot 
                        offset-x="8" 
                        offset-y="10"
                    >
                        <hb-icon>mdi-menu</hb-icon>
                    </v-badge>
                    <hb-icon v-else>mdi-menu</hb-icon>
                </template>
                <v-list>
                    <v-list-item-group v-model="vmodel">
                        <v-list-item 
                            v-show="!tab.data.attrs.hide" 
                            :class="{ 'pr-10': tab.data.attrs.badgeCount }"
                            @click="menuClicked(i, tab)" 
                            v-for="(tab, i) in slotTabs"
                            :value="tab.data.attrs.defaultTab ? tab.data.attrs.defaultTab.replace('#', '') : undefined"
                            :href="tab.data.attrs.href" 
                            :to="tab.data.attrs.to"
                            :target="tab.data.attrs.href ? tab.data.attrs.target : undefined"
                            :key="tab.key || `tab${i}`" 
                            :disabled="tab.data.attrs.disabled" 
                            :ripple="false"
                        >
                            <v-badge v-if="tab.data.attrs.badgeCount > 0"
                                :content="badgeContent(tab.data.attrs.badgeCount)"
                                :value="tab.data.attrs.badgeCount > 0" 
                                :color="tab.data.attrs.badgeColor ?? `#F26500`"
                                offset-y="18" 
                                offset-x="-5"
                            >
                                {{ tab.data.attrs.label }}
                            </v-badge>
                            <template v-else>
                                {{ tab.data.attrs.label }}
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </hb-menu>
        </template>
        <!-- Building Menu section in parent by utilizing slot based on navigation chevron visibility (use this option only as a last resort)-->
        <slot name="tab-menu" :isNavigationChevronsVisible="isNavigationChevronsVisible">
        </slot>
        <!-- Building Tabs -->
        <v-tabs 
            :style="{ width: showMenu ? `calc(100% - 48px)` : '100%' }" 
            :background-color="backgroundColor"
            :color="color" 
            v-model="vmodel" 
            v-bind="$attrs" 
            v-on="$listeners" 
            show-arrows 
            :slider-color="sliderColor"
        >
            <template v-if="slotTabs">
                <v-tab 
                    v-show="!tab.data.attrs.hide"
                    @click="$emit('tab-clicked', { index: i, tabObject: { ...tab.data.attrs, key: tab.key } })"
                    v-for="(tab, i) in slotTabs" 
                    :to="tab.data.attrs.to" 
                    :disabled="tab.data.attrs.disabled"
                    :href="tab.data.attrs.href || tab.data.attrs.defaultTab"
                    :target="tab.data.attrs.href ? tab.data.attrs.target : undefined" 
                    :key="tab.key || `tab${i}`"
                    :ripple="false" 
                    :class="computeClasses(tab)"
                >
                    <v-badge 
                        v-if="tab.data.attrs.badgeCount > 0" 
                        :content="badgeContent(tab.data.attrs.badgeCount)"
                        :value="tab.data.attrs.badgeCount > 0" 
                        :color="tab.data.attrs.badgeColor ?? `#F26500`"
                        offset-y="18" 
                        offset-x="-5"
                    >
                        {{ tab.data.attrs.label }}
                    </v-badge>
                    <template v-else>
                        {{ tab.data.attrs.label }}
                    </template>
                    <hb-tooltip
                        v-if="(tab.data.attrs.tooltipHeader || tab.data.attrs.tooltipBody && !(tab.data.attrs.badgeCount > 0)) && $vuetify.breakpoint.smAndUp"
                        bottom
                    >
                        <template v-if="tab.data.attrs.tooltipHeader" v-slot:header>
                            {{ tab.data.attrs.tooltipHeader }}
                        </template>
                        <template v-slot:item>
                            <hb-icon class="ml-2" small>{{ tab.data.attrs.tooltipIcon ?? 'mdi-information' }}</hb-icon>
                        </template>
                        <template v-if="tab.data.attrs.tooltipBody" v-slot:body>
                            <span v-html="tab.data.attrs.tooltipBody"></span>
                        </template>
                    </hb-tooltip>
                </v-tab>
            </template>
            <!-- This slot should get only used in quick fixes if normal tab options are insufficient or need to add <v-tab-item>-->
            <slot>
            </slot>
        </v-tabs>
    </div>
</template>

<script type="text/babel">

let HbTabsResponsiveWrapper

export default {
    name: 'HbTabsResponsive',
    data() {
        return {
            isNavigationChevronsVisible: false,
            observer: null
        }
    },
    computed: {
        vmodel: {
            get() {
                return this.$attrs.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        isBadgeCountPresent() {
            return this.slotTabs?.find(tab => tab?.data?.attrs?.badgeCount > 0 && !tab?.data?.attrs?.hide)
        },
        slotTabs() {
            return this.$slots?.tabs?.filter(tab => tab.tag)
        },
        showMenu() {
            return this.slotTabs && !this.hideMenu && this.isNavigationChevronsVisible
        }
    },
    props: {
        hideMenu: {
            type: Boolean,
            default: false
        },
        backgroundColor: {
            type: String,
            default: '#F9FAFB'
        },
        sliderColor: {
            type: String,
            default: '#00848E'
        },
        color: {
            type: String,
            default: '#101318'
        }
    },
    mounted() {
        HbTabsResponsiveWrapper = this.$refs['responsive-tabs-wrapper']
        // Change previous arrow position as per figma keeping other functionalities same
        this.changePreviousChevronPosition()
        // Observe for the existence of chevrons and show/hide menu since the menu should only appear when navigation chevrons are present
        this.observeNavigationChevronAndUpdateMenuVisibility()
    },
    destroyed() {
        this.observer?.disconnect()
    },
    methods: {
        badgeContent(badgeCount) {
            return badgeCount > 99 ? '99+' : Math.round(badgeCount)
        },
        computeClasses(tab) {
            return [
                tab?.data?.attrs?.badgeCount > 0 && 'pr-10',
                ...(tab?.data?.staticClass ? [tab.data.staticClass] : [])
            ].filter(Boolean);
        }
        ,
        changePreviousChevronPosition() {
            const prevElement = HbTabsResponsiveWrapper.querySelector('.v-slide-group__prev')
            const contentElement = HbTabsResponsiveWrapper.querySelector('.v-slide-group__wrapper')
            contentElement.insertAdjacentElement('afterend', prevElement)
        },
        observeNavigationChevronAndUpdateMenuVisibility() {
            // Create a MutationObserver instance and pass a callback function to handle mutations
            const nextElement = HbTabsResponsiveWrapper.querySelector('.v-slide-group__next')
            const prevElement = HbTabsResponsiveWrapper.querySelector('.v-slide-group__prev')
            this.observer = new MutationObserver((mutationsList) => {
                mutationsList.forEach((mutation) => {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                        if (!(nextElement.classList.contains('v-slide-group__next--disabled') && prevElement.classList.contains('v-slide-group__prev--disabled'))) {
                            this.isNavigationChevronsVisible = true
                        }
                        else {
                            this.isNavigationChevronsVisible = false
                        }
                    }
                });
            });

            // Configure the observer to watch for attribute changes
            const config = {
                attributes: true, // Watch for attribute changes
                attributeFilter: ['class'], // Only watch the 'class' attribute
            };

            // Start observing the previous and next target element
            this.observer.observe(prevElement, config);
            this.observer.observe(nextElement, config);
        },
        menuClicked(i, tab) {
            this.$emit('tab-clicked', { index: i, tabObject: { ...tab.data.attrs, key: tab.key } })
        }
    }
}
</script>

<style scoped>
.hb-tabs-responsive-wrapper {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    color: #637381;

    .hb-tabs-responsive-menu {
        display: flex;
        align-self: center;
        justify-content: center;
        width: 48px;
    }
}


.hb-tabs-responsive-wrapper>>>.v-slide-group__prev {
    margin-left: 12px;
    flex: 1 1 22px;
    min-width: 22px;
}

.hb-tabs-responsive-wrapper>>>.v-slide-group__next {
    margin-left: 5px;
    margin-right: 10px;
    flex: 1 1 21px;
    min-width: 21px;
}

.hb-tabs-responsive-wrapper>>>.v-tabs-slider-wrapper {
    height: 3px !important;
}

.hb-tabs-responsive-wrapper>>>.v-tab,
.hb-tabs-responsive-wrapper>>>a.v-tab {
    text-transform: capitalize !important;
    letter-spacing: normal !important;
    font-size: 14px !important;
    font-weight: normal !important;
}

.hb-tabs-responsive-wrapper .v-slide-group__wrapper {
    border-bottom-width: 1px !important;
}

.theme--light.hb-tabs-responsive-wrapper.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.hb-tabs-responsive-wrapper.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.hb-tabs-responsive-wrapper.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-btn,
.theme--light.hb-tabs-responsive-wrapper.v-tabs>.v-tabs-bar .v-tab--disabled {
    color: #637381 !important;
}

.theme--light.hb-tabs-responsive-wrapper.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active):hover,
.theme--light.hb-tabs-responsive-wrapper.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon:hover,
.theme--light.hb-tabs-responsive-wrapper.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-btn:hover,
.theme--light.hb-tabs-responsive-wrapper.v-tabs>.v-tabs-bar .v-tab--disabled:hover {
    color: #101318 !important;
    background-color: #F9FAFB;
}

.hb-tabs-responsive-wrapper>>>.v-tab--active,
.hb-tabs-responsive-wrapper>>>a.v-tab--active {
    font-weight: 500 !important;
}
</style>