<template>
  <div>
    <v-container class="payment-method-container">
      <v-row  v-if="!dense_header">
        <v-col><strong>ACH/Bank Account</strong></v-col>
      </v-row>
      <div v-show="achMethods.length">
        <v-row dense class="mt-0">
          <v-col><strong>Payment Method</strong></v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-radio-group dense v-model="method" :mandatory="false"   @change="handleMethodChange">
              <v-radio value="existing">
                <template v-slot:label>
                  <v-select
                    :disabled="method !== 'existing'"
                    hide-details
                    :value="payment.payment_method_id"
                    :items="achMethods"
                    item-value="id"
                    item-text="card_end"
                    :error-messages="errors.collect('existing_payment_method_id')"
                    placeholder="Account On File"
                    outlined
                    dense
                    @change="handleUpdate('payment','payment_method_id',$event)"
                    v-validate="method === 'existing'? 'required' : ''"
                    :class="{'custom-field-error' : errors.first('existing_payment_method_id')}"
                    name="existing_payment_method_id"
                    id="existing_payment_method_id"
                    data-vv-name="existing_payment_method_id"
                    data-vv-as="Account On File"
                  >
                    <template v-slot:selection="data" >
                      {{data.item.card_type | capitalize}}  ****{{data.item.card_end.slice(-4)}}
                    </template>

                    <template v-slot:item="data" >

                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>{{data.item.name_on_card}} </strong>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{data.item.card_type | capitalize}} ****{{data.item.card_end.slice(-4)}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </template>
              </v-radio>

              <v-radio value="new">
                <template v-slot:label>
                  <v-icon class="mr-3">mdi-bank</v-icon> New Bank Account
                </template>
              </v-radio>
            </v-radio-group>

          </v-col>
        </v-row>
      </div>

      <div v-show="method === 'new'">
        <v-row  v-if="!dense_header" dense class="mt-1 pb-3">
          <v-col class="pt-2">
            <strong>Account Information</strong>
          </v-col>
          <v-col cols="7" class="d-flex flex-row-reverse" v-if="paymentConfig.SHOW_SAVE_PAYMENT">
            <v-checkbox class="pt-0" style="margin-top: 2px;" :disabled="auto_charge" hide-details :input-value="payment_method.save_to_account" @change="updateSaveToAccount($event)" label="Save Payment Method"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="py-0">
            <strong class="label-heading-1">Account Information</strong>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6">
            <v-text-field
              :class="{'custom-field-error' : errors.first('first')}"
              hide-details
              :value="payment_method.first"
              v-validate="'required|max:45'"
              data-vv-name="first"
              data-vv-as="First Name"
              :error-messages="errors.collect('first')"
              label="First Name*"
              id="account-first-name"
              name="account-first-name"
              autocomplete="cc-account-first-name"
              @input="handleInputUpdate('payment_method','first',$event)"
              >
            </v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              :class="{'custom-field-error' : errors.first('last')}"
              hide-details
              :value="payment_method.last"
              v-validate="'required|max:45'"
              data-vv-name="last"
              data-vv-as="Last Name"
              :error-messages="errors.collect('last')"
              label="Last Name*"
              id="account-last-name"
              name="account-last-name"
              autocomplete="cc-account-last-name"
              @input="handleInputUpdate('payment_method','last',$event)"
              >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6">
            <v-select
              :class="{'custom-field-error' : errors.first('account_type')}"
              hide-details
              :value="payment_method.account_type"
              :items="account_types"
              v-validate="'required'"
              data-vv-name="account_type"
              data-vv-as="Account Type"
              :error-messages="errors.collect('account_type')"
              label="Account Type*"
              @change="handleUpdate('payment_method','account_type',$event)"
              >
            </v-select>
          </v-col>

          <v-col cols="6">
            <v-text-field
              :class="{'custom-field-error' : errors.first('routing_number')}"
              tile
              hide-details
              :value="payment_method.routing_number"
              data-vv-name="routing_number"
              v-validate="'required|numeric|max:45'"
              data-vv-as="Routing Number"
              :error-messages="errors.collect('routing_number')"
              label="Routing Number*"
              id="account-routing-number"
              name="account-routing-number"
              autocomplete="cc-account-routing-number"
              @input="handleInputUpdate('payment_method','routing_number',$event)"
              >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-text-field
              :class="{'custom-field-error' : errors.first('account_number')}"
              tile
              hide-details
              :value="payment_method.account_number"
              data-vv-name="account_number"
              v-validate="'required|numeric|max:45'"
              data-vv-as="Account Number"
              :error-messages="errors.collect('account_number')"
              label="Account Number*"
              id="account-number"
              name="account-number"
              autocomplete="cc-account-number"
              @input="handleInputUpdate('payment_method','account_number',$event)"
              >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row dense v-if="!dense_header">
          <v-col>
            <v-textarea
              :class="{'custom-field-error' : errors.first('notes')}"
              rows="2"
              hide-details
              :value="payment.notes"
              data-vv-name="notes"
              data-vv-as="Notes"
              :error-messages="errors.collect('notes')"
              label="Notes"
              id="account-notes"
              name="account-notes"
              autocomplete="cc-account-notes"
              @input="handleInputUpdate('payment','notes',$event)"
              >
            </v-textarea>
          </v-col>
        </v-row>

      </div>

    </v-container>


  </div>
</template>

<script type="text/babel">
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import PaymentMethodBase from './PaymentMethodBase.vue';
    import { mapGetters, mapMutations } from "vuex";
    import { EventBus } from '../../../EventBus';

    export default {
        data() {
            return {
                method: null,
                account_types:['Checking', 'Savings']
            }
        },
        components:{
            HbDatePicker
        },
        computed:{
            ...mapGetters({
              paymentConfig: 'paymentsStore/getPaymentSourceConfig',
              getErrors: 'paymentsStore/getErrors',
              payment_methods: 'paymentsStore/getContactPaymentMethods',
              payment_method: 'paymentsStore/getPaymentMethod',
              payment: 'paymentsStore/getPayment',
            }),
            achMethods(){
                return this.payment_methods.filter(pm => pm.type === 'ach');
            }
        },
        destroyed() {
        },
        mounted(){
            if(this.achMethods.length){
                this.method = 'existing';
            } else {
                this.method = 'new';
            }
        },
        props: ['auto_charge','dense_header'],
        methods:{
            ...mapMutations({
              setCheckErrors: 'paymentsStore/setCheckErrors',
              setPayment: 'paymentsStore/setPayment',
              setPaymentMethod: 'paymentsStore/setPaymentMethod',
              setPaymentObject: 'paymentsStore/setPaymentObject',
              setPaymentMethodObject: 'paymentsStore/setPaymentMethodObject',
            }),

            handleUpdate(obj, property_name, property_value){
              if(obj === 'payment'){
                this.setPayment({property: property_name, propertyValue: property_value});
              }
              if(obj === 'payment_method'){
                this.setPaymentMethod({property: property_name, propertyValue: property_value});
              }
            },

            handleInputUpdate : _.debounce(async function(obj, property_name, property_value) {
              this.handleUpdate(obj, property_name, property_value);
            }, 200),

            updateSaveToAccount(val){
              this.setPaymentMethod({property: 'save_to_account', propertyValue: !!val}); 
            },
            handleMethodChange(){
              this.$validator.reset();
              if(this.method!='existing'){
                this.payment.payment_method_id = null;
              }
            },
            validateFields(){
              if(this.method === 'existing'){
                return this.$validator.validate('existing_payment_method_id')
              }
              if(this.method === 'new'){
                return this.$validator.validateAll();
              }
            }
        },
        watch:{
          auto_charge(){
            this.setPaymentMethod({property: 'save_to_account', propertyValue: this.auto_charge});
          }
        }


    }
</script>
<style scoped>
  .label-heading-1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101318;
  }
</style>
